import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import Observer from '../observer'

export const fragment = graphql`
  fragment ServiceIntroductionFragment on WordPress_Service_Servicepagefields {
    introduction {
      title
      text
    }
  }
`

const ServiceIntroduction = ({ introduction }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const ref = useRef()

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
        <WrapIntro ref={ref}>
          <WrapText style={titleAnimation}>
            <IntroTitle>{introduction.title}</IntroTitle>
            <IntroText dangerouslySetInnerHTML={{ __html: introduction.text }} />
          </WrapText>
        </WrapIntro>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 100vh 0 0 0;
  display: flex;
  flex-wrap: wrap;
  z-index: 2;

  @media (max-width: 1024px) {
    padding: 100vh 0 0 0;
  }

  @media (max-width: 650px) {
    padding: 0;
  }
`

const WrapIntro = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 22.5rem 12.5%;
  background-color: #fff;

  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 10.5rem 7.5%;
    margin-left: 0;
    margin-top: 0;
  }

  @media (max-width: 650px) {
    margin-left: 0;
    padding: 5rem 3.5rem;
  }
`

const WrapText = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
  }

  @media (max-width: 650px) {
  }
`

const IntroTitle = styled.h2`
  color: ${props => props.theme.colours.grey};
  width: 45%;
  font-size: 8rem;
  line-height: 1;
  max-width: 11ch;

  @media (max-width: 650px) {
    width: 100%;
    line-height: 1.33;
    font-size: 5rem;
    margin-bottom: 3rem;
    max-width: 100%;
  }
`

const IntroText = styled.div`
  width: 45%;

  @media (max-width: 650px) {
    width: 100%;
  }

  p {
    color: ${props => props.theme.colours.grey};

    @media (max-width: 650px) {
      width: 100%;
      font-size: 2.3rem;
    }
  }
`

export default ServiceIntroduction
