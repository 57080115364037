import React, { useState, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useSpring, animated } from "react-spring"
import ReactIdSwiperCustom from "react-id-swiper/lib/ReactIdSwiper.custom"
import { Swiper } from "swiper/js/swiper.esm"
import WorkflowPlaySvg from "../../images/workflow-play.svg"
import SpaceshipSvg from "../../images/spaceship.svg"

import { aspectRatio } from "../../helpers/aspect-ratio"
import Observer from "../observer"
import "swiper/css/swiper.css"

export const fragment = graphql`
  fragment ServiceWorkflowFragment on WordPress_Service_Servicepagefields {
    workflow {
      showSlider
      text
      title
      workflowListTitle
      workflowList {
        title
      }
      workflowListDevTitle
      workflowListDev {
        title
      }
      launchLogo {
        sourceUrl
      }
    }
  }
`

const ServiceWorkflow = ({ workflow }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [animatedImage, setAnimatedImage] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const [swiper, updateSwiper] = useState(null)
  const ref = useRef()

  const params = {
    Swiper,
    slidesPerView: 1.8,
    spaceBetween: 20,
    slidesOffsetBefore: 25,
    slidesOffsetAfter: 25,
    grabCursor: true,
    freeMode: true,
    threshold: 20,
    breakpoints: {
      760: {
        spaceBetween: 30,
        slidesPerView: 2.8,
        slidesOffsetBefore:
          typeof window !== `undefined` ? window.innerWidth * 0.125 : 0,
        slidesOffsetAfter:
          typeof window !== `undefined` ? window.innerWidth * 0.2 : 0,
      },
      1024: {
        spaceBetween: 40,
        slidesPerView: 4.8,
        slidesOffsetBefore:
          typeof window !== `undefined` ? window.innerWidth * 0.125 : 0,
        slidesOffsetAfter:
          typeof window !== `undefined` ? window.innerWidth * 0.2 : 0,
      },
    },
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 },
  })

  if (!workflow.showSlider) {
    return null
  }

  return (
    <>
      <Outer>
        <Observer
          element={ref.current}
          toggle={animatedTitle}
          setToggle={setAnimatedTitle}
        >
          <Observer
            element={ref.current}
            toggle={animatedImage}
            setToggle={setAnimatedImage}
          >
            <WrapIntro ref={ref}>
              <WrapText style={titleAnimation}>
                <Title>{workflow.title}</Title>
                <Text>{workflow.text}</Text>
              </WrapText>
            </WrapIntro>
          </Observer>
        </Observer>
      </Outer>
      <WrapSLider>
        <ReactIdSwiperCustom getSwiper={updateSwiper} {...params}>
          {workflow.workflowList?.map((item, index) => (
            <SlideGradient key={`${item.title}-${index}`}>
              <WrapSlideContent>
                <IndexNumber>
                  {index > 8 ? index + 1 : `0${index + 1}`}
                </IndexNumber>
                <SlideTitle>{item.title}</SlideTitle>
              </WrapSlideContent>
              {index === 0 && (
                <Indicator>
                  <WorkflowPlaySvg />
                  <span>
                    {workflow.workflowListTitle
                      ? workflow.workflowListTitle
                      : "Design"}
                  </span>
                </Indicator>
              )}
              <Line />
            </SlideGradient>
          ))}
          {workflow.workflowListDev?.map((item, index) => (
            <SlideGradient key={`${item.title}-${index}`}>
              <WrapSlideContent>
                <IndexNumber>
                  {workflow.workflowList.length + index > 8
                    ? workflow.workflowList.length + index + 1
                    : `0${workflow.workflowList.length + index + 1}`}
                </IndexNumber>
                <SlideTitle>{item.title}</SlideTitle>
                {index === workflow.workflowListDev.length - 1 && (
                  <WrapLaunch>
                    {workflow.launchLogo ? (
                      <img
                        src={workflow.launchLogo.sourceUrl}
                        alt={workflow.launchLogo.alttext}
                      />
                    ) : (
                      <SpaceshipSvg />
                    )}
                  </WrapLaunch>
                )}
              </WrapSlideContent>
              {index === 0 && (
                <Indicator>
                  <WorkflowPlaySvg />
                  <span>
                    {workflow.workflowListDevTitle
                      ? workflow.workflowListDevTitle
                      : "Development"}
                  </span>
                </Indicator>
              )}
              <Line />
            </SlideGradient>
          ))}
        </ReactIdSwiperCustom>
        <FadeGradient />
        <WrapSliderAdds />
      </WrapSLider>
    </>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 16.5rem 12.5% 0 12.5%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  z-index: 2;

  @media (max-width: 1024px) {
    padding: 10.5rem 7.5% 0 7.5%;
  }

  @media (max-width: 650px) {
    margin: 0 auto;
    padding: 8.5rem 3.5rem 0 3.5rem;
  }
`

const WrapIntro = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 0;
  }

  @media (max-width: 650px) {
    margin-left: 0;
  }
`

const WrapText = styled(animated.div)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8rem;

  @media (max-width: 1024px) {
  }

  @media (max-width: 650px) {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
`

const Title = styled.h3`
  width: 40%;
  font-size: 8rem;
  line-height: 1;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.grey};

  @media (max-width: 650px) {
    width: 100%;
  }
`

const Text = styled.p`
  width: 40%;
  font-size: 1.6rem;
  line-height: 1.9;
  font-family: ${props => props.theme.fonts.circular};
  color: ${props => props.theme.colours.grey};

  @media (max-width: 650px) {
    width: 100%;
    font-size: 2.3rem;
  }
`

const WrapSLider = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  padding-top: 50px;
  background-color: #fff;

  @media (max-width: 650px) {
    /* padding-bottom: 50px; */
  }

  ${ReactIdSwiperCustom} > * {
    overflow: visible !important;
  }
`

const FadeGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: block;
  z-index: 3;
  pointer-events: none;

  @media (max-width: 650px) {
    display: none;
  }
`

const Indicator = styled.div`
  position: absolute;
  height: 30px !important;
  width: auto !important;
  top: unset !important;
  left: 0 !important;
  bottom: -88px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    height: 30px !important;
  }

  @media (max-width: 650px) {
    bottom: -55px !important;
  }

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(100% + 1.5rem);
    height: 50%;
    background-color: #fff;
    z-index: -1;
  }

  svg {
    width: 29px;
    height: 29px;
    transform: translateY(0.25rem);
    margin-right: 0.75rem;

    @media (max-width: 650px) {
      width: 35px;
      height: 35px;
    }

    path {
      fill: ${props => props.theme.colours.pink};
      stroke-linecap: rounded;
    }
  }

  span {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    font-size: 3rem;
    width: calc(100% + 50px);
    overflow-wrap: normal;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 650px) {
      font-size: 4.5rem;
    }
  }
`

const WrapSliderAdds = styled.div`
  position: absolute;
  bottom: 42px;
  left: 0;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0 2rem 12.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 0 0 0 7.5%;
  }

  @media (max-width: 650px) {
    padding: 2rem 0 0 3.5rem;
  }
`

const Line = styled.div`
  position: absolute;
  top: unset !important;
  bottom: -72px !important;
  height: 3px !important;
  width: 120% !important;
  background-color: ${props => props.theme.colours.pink};
  transform: translateY(0.5rem);
  z-index: -2;

  @media (max-width: 1024px) {
    width: 130% !important;
  }

  @media (max-width: 650px) {
    width: 109.5% !important;
    bottom: -40px !important;
  }
`

const SlideGradient = styled.div`
  position: relative;
  ${aspectRatio(214, 265)}
  background: rgb(228,92,224);
  background: linear-gradient(
    215deg,
    rgba(228, 92, 224, 1) 10%,
    rgba(77, 169, 226, 1) 100%
  );
  border-radius: 2em;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
  margin-bottom: 135px;

  &:last-child {
    ${Line} {
      width: 100% !important;
    }
  }
`

const WrapSlideContent = styled.div`
  width: calc(100% - 6rem);
  height: calc(100% - 6rem);
  padding: 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
`

const IndexNumber = styled.span`
  font-size: 5rem;
  line-height: 1.4;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.grey};

  @media (max-width: 650px) {
    font-size: 7rem;
  }
`

const SlideTitle = styled.h4`
  font-size: 2rem;
  line-height: 1.4;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.white};

  @media (max-width: 1024px) {
    font-size: 2.8rem;
  }
`

const WrapLaunch = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 5;
`

export default ServiceWorkflow
