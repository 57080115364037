import React, { useRef, useContext } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ServiceHero from '../components/single-service/hero2'
import ServiceIntroduction from '../components/single-service/introduction'
import ServiceWhatMakeUs from '../components/single-service/what-make-us'
import ServiceBenefits from '../components/single-service/benefits'
import ServiceWorkflow from '../components/single-service/workflow'
import ServiceFeaturedProject from '../components/single-service/featured-projects'
import ServiceStartProject from '../components/single-service/start-project'
import ServiceRelated from '../components/single-service/related2'
import InView from '../components/in-view'
import { ButtonContext } from '../components/context/button-context'
import SEO from '../components/single-service/seo'

const SingleService = ({ data, pageContext }) => {
  const [buttonTriggers, setButtonTriggers] = useContext(ButtonContext)
  const sectionRef = useRef()

  return (
    <Layout>
      <SEO
        pageTitle={pageContext.title}
        slug={pageContext.slug}
        {...data.wordPress.serviceBy.seo}
      />
      <ServiceHero hero={data.wordPress.serviceBy.servicepageFields.heroInfos} />
      <ServiceIntroduction introduction={data.wordPress.serviceBy.servicepageFields.introduction} />
      <ServiceWhatMakeUs what={data.wordPress.serviceBy.servicepageFields.whatMakeUs} />
      <ServiceBenefits benefits={data.wordPress.serviceBy.servicepageFields.benefits} />
      <ServiceWorkflow workflow={data.wordPress.serviceBy.servicepageFields.workflow} />
      <ServiceFeaturedProject featured={data.wordPress.serviceBy.servicepageFields.featured} />
      <InView element={sectionRef.current} setToggle={setButtonTriggers} location="cracking">
        <div ref={sectionRef}>
          <ServiceStartProject
            start={data.wordPress.serviceBy.servicepageFields.startProject}
            hero={data.wordPress.serviceBy.servicepageFields.heroInfos}
            data={data.wordPress.globalOptions}
          />
          <ServiceRelated related={data.wordPress.services.edges} title={pageContext.title} />
        </div>
      </InView>
    </Layout>
  )
}

export default SingleService

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      serviceBy(serviceId: $wordpressId) {
        title
        ...SeoServiceFragment
        servicepageFields {
          ...ServiceHeroNewFragment
          ...ServiceIntroductionFragment
          ...ServiceWhatFragment
          ...ServiceBenefitsFragment
          ...ServiceWorkflowFragment
          ...ServiceFeaturedFragment
          ...ServiceStartFragment
        }
      }
      services(where: { orderby: { field: MENU_ORDER, order: ASC } }) {
        edges {
          node {
            slug
            servicepageFields {
              ...ServiceRelatedFragment
            }
          }
        }
      }
      globalOptions {
        optionFields {
          contactInfos {
            emailProjects
            phone
            phoneNyc
          }
          startProject {
            ctaText
            formTitle
            introText
            sideText
          }
        }
      }
    }
  }
`
