import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { useTrail, useSpring, animated } from 'react-spring'
import Observer from '../observer'
import { aspectRatio } from '../../helpers/aspect-ratio'
import { TransitionContext } from '../context/transition-context'

export const fragment = graphql`
  fragment ServiceRelatedFragment on WordPress_Service_Servicepagefields {
    featuredInfos {
      subtitle
      title
      image {
        sourceUrl
        mediaItemId
        modified
        imageFile {
          childImageSharp {
            fluid(maxWidth: 800, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ServiceRelated = ({ related, title }) => {
  // eslint-disable-next-line no-unused-vars
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animationToggle, setAnimationToggle] = useState(false)
  const [imageIndex, setImageIndex] = useState(0)
  const [imageIndexOnload, setImageIndexOnload] = useState(0)
  const [imgFade, setImgFade] = useState(true)
  const ref = useRef()
  const sectionRef = useRef()

  const relatedItemAnimation = useTrail(related.length, {
    opacity: animationToggle ? 1 : 0,
    x: animationToggle ? 0 : 20,
    from: { opacity: 0, x: 20 },
    config: {
      mass: 1,
      tension: 2500,
      friction: 175
    },
    delay: animationToggle ? 300 : 50
  })

  const fadeImg = useSpring({
    opacity: imgFade ? 1 : 0,
    from: { opacity: 0 },
    config: {
      mass: 3,
      tension: 500,
      friction: 50
    }
  })

  useEffect(() => {
    related.map((item, index) => {
      if (title.toLowerCase() === item.node.servicepageFields.featuredInfos.title.toLowerCase()) {
        setImageIndexOnload(index)
        setImageIndex(imageIndexOnload)
      }
    })
    // eslint-disable-next-line no-unused-vars
  }, [imageIndexOnload, related, title])

  return (
    <Outer ref={sectionRef}>
      <WrapRelated ref={ref}>
        <Observer element={ref.current} toggle={animationToggle} setToggle={setAnimationToggle}>
          <WrapLeft>
            {relatedItemAnimation.map(({ x, ...rest }, index) => {
              const relatedItem = related[index]
              return (
                <RelatedItem
                  key={index}
                  to={`/services/${relatedItem.node.slug}`}
                  onClick={event => {
                    event.preventDefault()
                    if (typeof window !== `undefined`) {
                      if (!window.location.pathname.includes(relatedItem.node.slug)) {
                        setTransitionActive(true)
                        setTimeout(() => {
                          navigate(`/services/${relatedItem.node.slug}`)
                          setTransitionActive(false)
                        }, 500)
                      }
                    }
                  }}
                  onMouseEnter={event => {
                    setImgFade(false)
                    setTimeout(() => {
                      setImageIndex(index)
                      setImgFade(true)
                    }, 150)
                  }}
                  onMouseLeave={event => {
                    setImgFade(false)
                    setTimeout(() => {
                      setImageIndex(imageIndexOnload)
                      setImgFade(true)
                    }, 150)
                  }}
                  style={{
                    ...rest,
                    transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
                  }}
                >
                  <RelatedItemWrapper
                    className={
                      title.toLowerCase() ===
                      relatedItem.node.servicepageFields.featuredInfos.title.toLowerCase()
                        ? 'active'
                        : ''
                    }
                  >
                    <h2> {relatedItem.node.servicepageFields.featuredInfos.title} </h2>
                  </RelatedItemWrapper>
                </RelatedItem>
              )
            })}
            <SmallTitle>More Services</SmallTitle>
          </WrapLeft>
          <WrapRight style={fadeImg}>
            <RelatedImg
              fluid={
                related[imageIndex].node.servicepageFields.featuredInfos.image.imageFile
                  .childImageSharp.fluid
              }
            />
          </WrapRight>
        </Observer>
      </WrapRelated>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 16.5rem 12.5%;
  background-color: #fff;
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5%;
  }

  @media (max-width: 650px) {
    padding: 12rem 3.5rem 65px 3.5rem;
  }
`

const WrapRelated = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const SmallTitle = styled.h4`
  position: absolute;
  top: 0;
  left: -10rem;
  transform: rotate(90deg) translate(55%, 0%);
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  @media (max-width: 650px) {
    transform: rotate(0deg) translate(0, 0);
    font-size: 1.5rem;
    top: -25px;
    left: 0;
  }
`

const WrapLeft = styled.div`
  position: relative;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 50%;

  @media (max-width: 650px) {
    width: 100%;
  }
`

const WrapRight = styled(animated.div)`
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 50%;
  display: block;

  @media (max-width: 650px) {
    max-width: calc(100% - 50px);
    display: none;
  }
`

const RelatedItemWrapper = styled.div`
  opacity: 0.2;
  transition: opacity 0.3s;

  &.active {
    opacity: 1;
  }
`

const RelatedImg = styled(Img)`
  ${aspectRatio(376, 274)}
  margin-bottom: 2rem;

  img {
    object-fit: contain !important;
  }
`

const RelatedItem = styled(animated(Link))`
  width: 100%;
  margin-bottom: 0.15rem;
  display: inline-block;
  /* margin-bottom: 10rem; */
  transition: opacity 0.25s;

  @media (max-width: 1024px) {
    width: calc(50% - 2.5rem);
    padding: 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
    opacity: 1;

    &:first-child {
      margin-bottom: 5rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 5rem;
    letter-spacing: -0.075rem;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    text-align: center;

    @media (max-width: 1024px) {
      font-size: 2rem;
      margin: 0 auto;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }

  &:hover > * {
    opacity: 1;
  }
`

export default ServiceRelated
