import React, { useState, useContext, useEffect, useRef } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { useSpring, animated } from 'react-spring'
import Observer from '../observer'
import { TransitionContext } from '../context/transition-context'

import MaskedImage from '../masked-image'
import MaskedVideo from '../masked-video'

import { aspectRatio } from '../../helpers/aspect-ratio'

export const fragment = graphql`
  fragment ServiceFeaturedFragment on WordPress_Service_Servicepagefields {
    featured {
      title
      projectsList {
        ... on WordPress_Project {
          slug
          categories {
            nodes {
              name
            }
          }
          workFields {
            featuredInfos {
              subtitle
              title
              image {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imagePortrait {
                sourceUrl
                mediaItemId
                modified
                altText
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              videoSquare {
                mediaItemUrl
              }
              videoPortrait {
                mediaItemUrl
              }
              videoImageFallback {
                sourceUrl
                mediaItemId
                modified
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 800, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageOrVideo
            }
          }
        }
      }
    }
  }
`

const ServiceFeaturedProject = ({ featured }) => {
  const [transitionActive, setTransitionActive] = useContext(TransitionContext)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [isDesktop, setIsDesktop] = useState()
  const ref = useRef()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }
  }, [])

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
        <WrapIntro ref={ref}>
          <WrapText style={titleAnimation}>
            <p>{featured.title}</p>
          </WrapText>

          <WorkFeatured>
            {featured.projectsList.map((project, i) => (
              <WorkItem
                key={project.slug}
                to={`/work/${project.slug}`}
                onClick={event => {
                  event.preventDefault()
                  setTransitionActive(true)
                  setTimeout(() => {
                    navigate(`/work/${project.slug}`)
                    setTransitionActive(false)
                  }, 500)
                }}
              >
                <WrapWorkImage>
                  {project.workFields.featuredInfos.imageOrVideo === 'image' && (
                    <MaskedImage
                      fluid={
                        (i === 0 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 3 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 4 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 7 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 8 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 11 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 12 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 15 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 16 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 19 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 20 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 223 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 24 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 27 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 28 && project.workFields.featuredInfos.imagePortrait) ||
                        (i === 31 && project.workFields.featuredInfos.imagePortrait)
                          ? project.workFields.featuredInfos.imagePortrait.imageFile.childImageSharp
                              .fluid
                          : project.workFields.featuredInfos.image.imageFile.childImageSharp.fluid
                      }
                      alt={project.workFields.featuredInfos.image.altText}
                      hover="true"
                    />
                  )}
                  {project.workFields.featuredInfos.imageOrVideo === 'video' && (
                    <>
                      {isDesktop === true && (
                        <MaskedVideo
                          video={
                            (i === 0 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 3 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 4 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 7 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 8 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 11 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 12 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 15 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 16 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 19 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 20 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 223 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 24 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 27 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 28 && project.workFields.featuredInfos.videoPortrait) ||
                            (i === 31 && project.workFields.featuredInfos.videoPortrait)
                              ? project.workFields.featuredInfos.videoPortrait.mediaItemUrl
                              : project.workFields.featuredInfos.videoSquare.mediaItemUrl
                          }
                          alt={project.workFields.featuredInfos.image.altText}
                          hover="true"
                        />
                      )}
                      {!isDesktop === true && (
                        <MaskedImage
                          style={{ position: 'absolute' }}
                          fluid={
                            project.workFields.featuredInfos.videoImageFallback.imageFile
                              .childImageSharp.fluid
                          }
                          alt={project.workFields.featuredInfos.image.altText}
                          hover="true"
                          decoding="sync"
                        />
                      )}
                    </>
                  )}
                </WrapWorkImage>
                <ItemDesc>
                  {project.categories.nodes.map((cat, n) => (
                    <span key={n}>{cat.name} </span>
                  ))}
                  <h2>{project.workFields.featuredInfos.title}</h2>
                  <p>{project.workFields.featuredInfos.subtitle}</p>
                </ItemDesc>
              </WorkItem>
            ))}
            <ProjectsButton
              to="/work"
              onClick={event => {
                event.preventDefault()
                setTransitionActive(true)
                setTimeout(() => {
                  navigate(`/work`)
                  setTransitionActive(false)
                }, 500)
              }}
            >
              View all projects
            </ProjectsButton>
          </WorkFeatured>
        </WrapIntro>
      </Observer>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 14.5rem 12.5% 0 12.5%;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  z-index: 5;

  @media (max-width: 1024px) {
    padding: 14.5rem 7.5% 0 7.5%;
  }

  @media (max-width: 650px) {
    padding: 0 3.5rem;
  }
`

const WrapIntro = styled.div`
  width: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    order: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
    margin-top: 0;
  }

  @media (max-width: 650px) {
    justify-content: space-around;
    margin-left: 0;
  }
`

const WrapText = styled(animated.div)`
  width: 40%;

  @media (max-width: 1024px) {
    width: 40%;
    margin-left: 0;
  }

  @media (max-width: 650px) {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0;
  }

  p {
    font-size: 8rem;
    line-height: 1;
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.grey};
    transform: translateY(15%);

    @media (max-width: 650px) {
      font-size: 7rem;
    }
  }
`

const WorkFeatured = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 15rem;
  padding-top: 1rem;
  width: 100%;

  @media (max-width: 650px) {
    margin: 5rem auto;
    padding-bottom: 5rem;
  }
`

const WrapWorkImage = styled.div`
  position: relative;

  ${Img} {
    top: 0;
    left: 0;
  }
`

const WorkItem = styled(Link)`
  color: ${props => props.theme.colours.grey};
  width: calc(49% - 2rem);

  @media (max-width: 769px) {
    width: 47%;
  }

  &:hover h2 {
    color: ${props => props.theme.colours.pink};
  }

  ${WrapWorkImage} {
    ${aspectRatio(474, 658)}
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
    margin-bottom: 5rem;

    &:nth-child(odd) {
      width: 100%;

      ${WrapWorkImage} {
        width: 100%;
        padding: 0;
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(even) {
      width: 100%;

      ${WrapWorkImage} {
        ${aspectRatio(474, 658)}
        width: 100%;
        padding: 0;
      }
    }
  }

  @media (min-width: 650px) {
    &:nth-child(4n + 1) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(4n) {
      width: 42%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 650px) {
        width: 100%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
      }
    }
  }

  @media (min-width: 700px) {
    &:nth-child(4n + 1) {
      width: 47%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
      }
    }

    &:nth-child(4n) {
      width: 47%;
      padding: 0 1rem;
      margin-bottom: 5rem;

      @media (max-width: 769px) {
        width: 47%;
        margin-bottom: 5rem;
      }

      ${WrapWorkImage} {
        ${aspectRatio(537, 469)}
      }
    }
  }
`

const ItemDesc = styled.div`
  margin-top: 2.5rem;
  span {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 0.22rem;
    text-transform: uppercase;
    margin-right: 1.5rem;
    display: inline-block;
    line-height: 1.75;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 1.75rem;
    }
  }

  h2 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    letter-spacing: -0.075rem;
    transition: color 0.25s ease;
    margin-top: 1rem;

    @media (max-width: 650px) {
      margin-top: 2rem;
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.circular};
    font-weight: 300;
    font-size: 1.6rem;
    margin-bottom: 0;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }

    @media (max-width: 650px) {
      margin-left: 0;
      font-size: 2.2rem;
    }
  }
`

const ProjectsButton = styled(animated(Link))`
  background: transparent;
  border: 0.2rem solid ${props => props.theme.colours.pink};
  padding: 1.5rem 2.75rem;
  color: ${props => props.theme.colours.pink};
  font-family: ${props => props.theme.fonts.circular};
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.1rem;
  border-radius: 3rem;
  margin-top: 0;
  min-width: 16rem;
  text-align: center;
  display: inline-block;
  transform: translateY(-2rem);
  transition: background-color 0.25s ease, color 0.25s ease;

  @media (max-width: 769px) {
    font-size: 1.6rem;
    margin-top: 2rem;
  }

  &:hover {
    background-color: ${props => props.theme.colours.pink};
    color: ${props => props.theme.colours.white};
  }
`

export default ServiceFeaturedProject
