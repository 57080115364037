import React, { useState, useRef } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import { useSpring, animated } from 'react-spring'
import { aspectRatio } from '../../helpers/aspect-ratio'
import Observer from '../observer'

export const fragment = graphql`
  fragment ServiceWhatFragment on WordPress_Service_Servicepagefields {
    whatMakeUs {
      title
      itemList {
        text
        title
      }
      backgroundImage {
        sourceUrl
        imageFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ServiceWhatMakeUs = ({ what }) => {
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const ref = useRef()

  const titleAnimation = useSpring({
    opacity: animatedTitle ? 1 : 0,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Observer element={ref.current} toggle={animatedTitle} setToggle={setAnimatedTitle}>
        <WrapWhat ref={ref} style={titleAnimation}>
          <WrapText dangerouslySetInnerHTML={{ __html: what.title }}></WrapText>
          <WrapItems>
            {what.itemList.map((item, index) => (
              <Item key={item.title}>
                <h3>{item.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: item.text }} />
              </Item>
            ))}
          </WrapItems>
        </WrapWhat>
      </Observer>
      <WrapImageBg>
        <BgImg
          fluid={what.backgroundImage.imageFile.childImageSharp.fluid}
          loading="eager"
          backgroundColor="#ffffff"
          decoding="sync"
        />
      </WrapImageBg>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  height: 100%;
  z-index: 2;
`

const WrapWhat = styled(animated.div)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10rem 12.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  z-index: 2;

  @media (max-width: 1100px) {
    flex-direction: column;
    /* position: aboslute; */
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 10rem 7.5%;
    height: 100%;
  }

  @media (max-width: 650px) {
    position: relative;
    top: unset;
    left: unset;
    transform: translate(0, 0);
    padding: 65px 3.5rem;
    height: 100%;
  }
`

const WrapImageBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${aspectRatio(1440, 820)}
  z-index: -1;

  /* @media (max-width: 1300px) {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${aspectRatio(1440, 820)}
    position: absolute;
    z-index: -1;
  } */

  @media (max-width: 1024px) {
    ${aspectRatio(528, 937)}
    width: 100%;
    margin-bottom: 8rem;
    position: absolute;
    top: 0;
  }

  @media (max-width: 769px) {
    ${aspectRatio(428, 1037)}
    width: 100%;
    margin-bottom: 8rem;
    position: absolute;
    top: 0;
  }

  &::after {
    content: '';
    display: block;
  }
`

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const WrapText = styled.div`
  width: 40%;
  font-family: ${props => props.theme.fonts.sofia};
  color: ${props => props.theme.colours.white};
  font-size: 8rem;
  line-height: 1;
  max-width: 7ch;

  @media (max-width: 650px) {
    max-width: 100%;
    font-size: 7rem;
    line-height: 1.1;
    margin-bottom: 5rem;

    br {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
    max-width: 15ch;
  }

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
  }

  p {
    font-family: ${props => props.theme.fonts.sofia};
    color: ${props => props.theme.colours.white};
    font-size: 8rem;
    line-height: 1;
    max-width: 7ch;

    @media (max-width: 1024px) {
      max-width: 100%;
      font-size: 7rem;
      line-height: 1.1;
      margin-bottom: 5rem;

      br {
        display: none;
      }
    }
  }
`

const WrapItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

const Item = styled.div`
  width: 45%;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: 100%;
    margin-top: 2rem;
  }

  h3 {
    color: ${props => props.theme.colours.white};
    font-size: 5.5rem;
    word-break: keep-all;
    overflow-wrap: normal;

    @media (max-width: 1300px) {
      font-size: 4rem;
    }

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }

  p {
    color: ${props => props.theme.colours.white};
    max-width: 28ch;

    @media (max-width: 1024px) {
      max-width: 40ch;
    }

    @media (max-width: 650px) {
      font-size: 2.3rem;
      max-width: 100%;
    }
  }
`

export default ServiceWhatMakeUs
